<template>
  <div
    :class="[
      'dropdown-menu',
      { 'nav-hidden': !navOpen && nestLevel === 1 },
      { 'is-open': navOpen },
      `lvl-${nestLevel}`,
    ]"
  >
    <ul :class="['sub-nav', `lvl-${nestLevel}`]">
      <li
        v-for="(item, idx) in mappedNav"
        :key="nestLevel + 'dropdownMenu' + idx + `parent${props.parentIndex}`"
        :class="[`lvl-${nestLevel}`]"
      >
        <CustomButton
          :to="isMobileOrTablet && nestLevel === 1 ? undefined : item.to"
          :href="isMobileOrTablet && nestLevel === 1 ? undefined : item.href"
          :is-menu-item="true"
          :class="{
            'is-bold': item.boldItem,
            'has-desc': item.shortDescription,
            'has-badge': item.hasBadge,
            'section-header': item.hasDropdownContent,
            'no-children': !item.hasDropdownContent,
          }"
          :aria-attrs="{
            'aria-haspopup': item.hasDropdownContent,
            'aria-expanded': openIndexes[idx],
          }"
          style-name="tertiary"
          style-color="black"
          @click-button="isMobileOrTablet ? toggleOpen(idx) : dropdownClicked()"
        >
          <template v-if="item.hasIcon" #icon>
            <slot name="menuIcon">
              <Store3Line />
            </slot>
          </template>

          {{ item?.title }}
          <Badge
            v-if="item.hasBadge"
            :theme="item?.badge?.theme?.toLowerCase()"
            :text="item?.badge?.text"
            size="small"
          />
          <div v-if="item.shortDescription" class="short-desc">
            {{ item.shortDescription }}
          </div>

          <template v-if="item.hasDropdownContent" #secondaryIcon>
            <div :class="['dropdown-svg', `lvl-${nestLevel}`]">
              <ArrowRightSLine v-if="nestLevel === 0" />
              <div v-else :class="['plus-minus', { active: openIndexes[idx] }]" />
            </div>
          </template>
        </CustomButton>

        <LazyDropdownMenu
          v-if="item.hasDropdownContent"
          :menu="item.link"
          :nest-level="props.nestLevel + 1"
          :parent-index="idx"
          :class="{ 'is-open': openIndexes[idx] }"
          @click="dropdownClicked()"
        >
          <!-- used as bold link for "all" parent link -->
          <li
            v-if="nestLevel === 1 && item?.link && item.link.length > 1 && isMobileOrTablet"
            :class="[`lvl-${nestLevel + 1}`]"
          >
            <CustomButton
              :to="item.to"
              :href="item.href"
              :is-menu-item="true"
              :class="{
                'is-bold': true,
                'section-header': false,
                'no-children': true,
                'tab-mob-only': true,
              }"
              style-name="tertiary"
              style-color="black"
            >
              {{ 'All ' + item?.title }}
            </CustomButton>
          </li>
        </LazyDropdownMenu>
      </li>
      <slot />
      <li v-if="featuredNav?.links?.length" class="featured-nav lvl-1">
        <div class="featured-nav-header" v-text="featuredNav?.header" />
        <ul>
          <li v-for="(link, idx) in featuredNav?.links" :key="`featured-nav-link-${idx}`">
            <CustomButton
              :to="link?.url"
              :class="['featured-nav-link']"
              :is-menu-item="true"
              style-name="tertiary"
              style-color="black"
              @click-button="dropdownClicked()"
            >
              {{ link?.title }}
            </CustomButton>
          </li>
        </ul>
      </li>
      <a
        v-if="contentImg?.url || contentImg?.header || contentImg?.description"
        :href="contentImg?.href || mappedNav?.[0]?.to || mappedNav?.[0]?.href || ''"
        class="content-img-section"
      >
        <div class="img-container">
          <CloudinaryImage
            :cloudinary-src="props.contentImg.url"
            :options="{ width: 350, height: 180 }"
            :alt="contentImg?.description || ''"
          />
        </div>
        <div v-if="contentImg.header" class="header" v-text="contentImg.header" />
        <div v-if="contentImg.description" class="desc" v-text="contentImg.description" />
      </a>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useNavStore } from '~/store/nav';
import { useDeviceStore } from '~/store/device';
import { Link, ProductHighlightSection } from '~/types/link-menu';
import { mapMenuItem } from '~/util/contentstack/csHelpers';

const props = defineProps({
  menu: {
    type: Array as () => Link[],
    default: () => [],
  },
  navTitle: {
    type: String,
    default: '',
  },
  nestLevel: {
    type: Number,
    default: 0,
  },
  navOpen: {
    type: Boolean,
  },
  contentImg: {
    type: Object as () => any,
    default: () => null,
  },
  highlightSection: {
    type: Object as () => ProductHighlightSection,
    default: () => null,
  },
  featuredNav: {
    type: Object as () => any,
    default: () => null,
  },
  parentIndex: {
    type: Number,
    required: true,
  },
});

const deviceStore = useDeviceStore();
const navStore = useNavStore();

const mappedNav = props.menu.map((item) => {
  return mapMenuItem(item);
});
// init array of open indexes the length of mappedNav
const openIndexes = ref(new Array(mappedNav?.length).fill(false));

const isMobileOrTablet = computed(() => deviceStore.isMobileOrTablet);

function dropdownClicked() {
  if (isMobileOrTablet?.value && props.nestLevel === 1) {
    navStore.navClose();
    navStore.setSubnavOpenIndex(null);
  } else if (!isMobileOrTablet?.value) {
    navStore.navClose();
    navStore.setSubnavOpenIndex(null);
  }
}
function toggleOpen(i: number) {
  // Close other open indexes
  openIndexes.value = openIndexes.value.map((item, idx) => {
    if (idx !== i) {
      return false;
    }
    return item;
  });
  openIndexes.value[i] = !openIndexes.value[i];
}
</script>
